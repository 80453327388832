<template>
  <l-default>
    <CFormVoiceChanger></CFormVoiceChanger>
  </l-default>
</template>

<script>
import LDefault from '../layouts/LDefault'
import CFormVoiceChanger from '../forms/CFormVoiceChanger'

export default {
  name: 'PageVoiceChanger',
  components: { LDefault, CFormVoiceChanger }
}
</script>

<style scoped>

</style>
